<template>
  <svg-icon :data="sData" class="pkg-ionicons" outlined v-bind="$attrs" />
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class IconCheckboxUnchecked extends Vue {
  sData: string = require("@icon/ionicons/square-outline.svg");
}
</script>
